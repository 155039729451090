<template>
  <div
    class="search"
    v-click-outside="hideResultBlock"
  >
    <label class="main-label width-100">
      <span class="main-label-form__title">Поиск</span>

      <el-input
        v-model="searchText"
        placeholder="Поиск"
        @focus="showResultBlock"
        @input="search"
      />
    </label>

    <div
      v-if="isShowResultBlock"
      class="result-block"
    >
      <table class="result-block-table">
        <thead class="result-block-table__head">
        <tr>
          <th width="10%">Кол.</th>
          <th width="65%">Название</th>
          <th width="25%">Цена</th>
        </tr>
        </thead>

        <tbody class="result-block-table__body">
        <tr
          v-for="item in resultList"
          :key="item.id"
          @click="selectProduct(item)"
        >
          <td width="10%">{{ item.count }}</td>
          <td width="60%">{{ item.title }}</td>
          <td width="30%">{{ getPriceWithProduct(item) }} р.</td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { getPriceToFormat } from '@/helper'

export default {
  name: 'Search',
  props: {
    list: {
      type: Array,
      default: () => ([])
    }
  },
  data () {
    return {
      resultList: [],
      searchText: '',
      isShowResultBlock: false
    }
  },
  watch: {
    list: {
      handler (newValue) {
        this.resultList = newValue
      },
      immediate: true
    }
  },
  methods: {
    selectProduct (product) {
      this.$emit('select-item', product)

      this.clearSearchText()
      this.hideResultBlock()
    },
    getPriceWithProduct (product) {
      return Number(product?.salePrice)
        ? `Цена со скидкой - ${getPriceToFormat(product.salePrice)}`
        : getPriceToFormat(product.price)
    },
    showResultBlock () {
      this.isShowResultBlock = true
    },
    hideResultBlock () {
      this.isShowResultBlock = false
    },
    search () {
      // TODO: СРОЧНО. Сделать запрос на бэк или перенести в воркер
      if (!this.searchText) {
        this.clearResultList()
      }

      const getFirstWords = (item) => {
        const words = item?.title
          || item?.productsNames
          || ''

        return words.toLowerCase()
      }

      const getSecondWords = (item) => {
        const words = item?.code || ''

        return words.toLowerCase()
      }

      this.resultList = this.list.filter(item => {
        const searchTextArray = this.searchText.toLowerCase().split(' ')

        return !Number(this.searchText)
          ? searchTextArray.every(str => getFirstWords(item).includes(str.toLowerCase()))
          : getSecondWords(item).includes(this.searchText.toLowerCase())
      })
    },
    clearResultList () {
      this.resultList = this.list
    },
    clearSearchText () {
      this.searchText = ''
    },
  }
}
</script>

<style lang="scss" src="./style.scss" />
