<template>
  <el-form
    ref="form"
    :model="fieldsResult"
    @keyup.enter.native="submit"
  >
    <el-form-item
      prop="products"
    >
      <search
        :list="fieldsOptions['products'].selectItems"
        @select-item="selectProduct"
      />

      <el-table
        :data="fieldsResult.products"
        lazy
        style="width: 100%"
        empty-text="Нет выбранных товаров"
        :class="classTable"
        :show-header="false"
      >
        <el-table-column
          v-for="header in [{
            value: 'title',
            text: 'Название'
          }]"
          :key="header.value"
          :prop="header.value"
          :label="header.text"
          :sortable="false"
          :resizable="false"
        />
        <el-table-column
          prop="price"
          label="Цена"
          :sortable="false"
          :resizable="false"
          width="120px"
        >
          <template v-slot:default="item">
            <el-input
              v-model="item.row.price"
              label="Цена"
              type="number"
            />
          </template>
        </el-table-column>
        <el-table-column
          prop="remove"
          label="Удалить"
          :sortable="false"
          :resizable="false"
          width="120px"
        >
          <template v-slot:default="item">
            <el-button
              class="el-button-40"
              plain
              type="warning"
              @click="removeSelectProduct(item.row.idForRemove)"
            >
              <span>Удалить</span>
            </el-button>
          </template>
        </el-table-column>
      </el-table>

      <div class="margin-top-10">
        <span><b>Итого: </b>{{ getPriceToFormat(sumResult) }} р.</span>
      </div>
    </el-form-item>

    <el-form-item
      v-for="(field, i) in fields"
      :key="new Date().getTime() + i"
      :prop="field"
      :rules="getFieldRules(field)"
      :error="validateErrors[field]"
    >
      <label
        v-if="fieldsOptions[field].type === 'select'"
        class="main-label width-100"
      >
        <span class="main-label-form__title">{{ fieldsOptions[field].name }}</span>

        <el-select
          v-if="isShowSelectInField"
          v-model="fieldsResult[field]"
          :multiple="fieldsOptions[field].type === 'select-multi'"
          class="width-100"
          placeholder="Выбирите"
          @change="clearErrorField(field)"
        >
          <el-option
            v-for="item in fieldsOptions[field].selectItems"
            :key="item.value"
            :disabled="item.disabled"
            :label="item[fieldsOptions[field].itemText]"
            :value="item[fieldsOptions[field].itemValue]"
          />
        </el-select>
      </label>
      <label
        v-else
        class="main-label width-100"
      >
        <span class="main-label-form__title">{{ fieldsOptions[field].name }}</span>

        <el-input
          v-model="fieldsResult[field]"
          :label="fieldsOptions[field].name"
          :type="fieldsOptions[field].type"
          :placeholder="fieldsOptions[field].name"
        />
      </label>
    </el-form-item>

    <div class="block-buttons block-buttons__right">
      <el-button
        type="warning"
        class="el-button-40"
        plain
        @click="closeModal(null)"
      >
        Закрыть
      </el-button>

      <el-button
        class="el-button-40"
        type="primary"
        plain
        @click="submit"
      >
        Создать
      </el-button>
    </div>
  </el-form>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import Search from '@/components/search'
import { getPriceToFormat } from '@/helper'

export default {
  name: 'Create-sales',
  props: {
    propsData: {
      type: Object,
      default: () => ({})
    }
  },
  components: {
    Search
  },
  data () {
    return {
      isSubmit: false,
      fields: Object.keys(this.propsData.fields || {}).filter(item => !this.propsData.fields[item].hidden),
      fieldsOptions: this.propsData.fields,
      fieldsResult: {
        products: [],
      },
      validateErrors: {},
      selectAddProduct: null,
      rules: {
        required: {
          required: true,
          message: 'Поле не должно быть пустым',
          trigger: 'submit'
        }
      },
      filteredSales: [],
      searchText: '',
      isShowResultBlock: false
    }
  },
  computed: {
    ...mapGetters('products', ['getData', 'getDataObject']),
    sumResult () {
      return this.fieldsResult.products.reduce((acc, item) => {
        const price = Number(item.salePrice) || Number(item.price)

        acc += price

        return acc
      }, 0)
    },
    classTable () {
      return this.isSubmit && this.fieldsResult.products.length < 1
        ? 'table-empty-text__red'
        : ''
    }
  },
  created () {
    this.fields.forEach(field => {
      this.$set(this.fieldsResult, field, this.fieldsOptions[field].value || '')
      this.$set(this.validateErrors, field, null)
    })

    this.$set(this.fieldsOptions, 'products', {
      ...this.fieldsOptions['products'],
      selectItems: this.getData
    })

    this.filteredSales = this.fieldsOptions['products'].selectItems
  },
  methods: {
    ...mapActions('modal', ['closeModal']),
    ...mapActions('info', ['loadMainData']),
    getPriceToFormat,
    getFieldRules (field) {
      return this.fieldsOptions[field].required
        ? this.rules.required
        : null
    },
    isShowSelectInField (field) {
      return this.fieldsOptions[field].type === 'select' || this.fieldsOptions[field].type === 'select-multi'
    },
    submit () {
      this.isSubmit = true

      this.$refs.form.validate(async (valid, obj) => {
        if (!valid) {
          // Костыль. Необходим для корректного вывода ошибок
          // Из за того что поля в форме рендерятся динамически
          // появляется проблема с вывыодом ошибок
          Object.keys(obj).forEach((field) => {
            this.validateErrors[field] = obj[field]?.[0]?.message
          })

          return
        }

        let result = {
          ...this.fieldsResult,
        }

        result.products = []
        result.productsIdWithPrice = []

        this.fieldsResult.products.forEach(product => {
          const productItemString = JSON.stringify({
            id: product.id,
            price: product.price
          })

          result.productsIdWithPrice.push(productItemString)
          result.products.push(product.id)
        })

        await this.$bus.emit('start-loader')
        await this.$store.dispatch(`${this.propsData.page}/create`, result)
        await this.propsData?.callback?.()
        await this.$bus.emit('stop-loader')
        await this.loadMainData()

        this.isSubmit = false
      })
    },
    selectProduct (product) {
      if (!product) {
        return
      }

      const price = Number(product.salePrice) || Number(product.price)
      const data = {
        id: product.id,
        idForRemove: this.fieldsResult.products.length,
        title: product.title,
        price
      }

      this.$set(this.fieldsResult.products, this.fieldsResult.products.length, data)
    },
    removeSelectProduct (productId) {
      this.fieldsResult.products = this.fieldsResult.products.filter(({ idForRemove }) => idForRemove !== productId)
    },
    clearErrorField (field) {
      this.validateErrors[field] = null
    }
  }
}
</script>

<style lang="scss" src="./style.scss" />
